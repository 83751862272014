.radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    transition: background-color 0.3s ease;

    &-active {
        background-color: var(--color-secondary);
    }
}

.custom-radio {
    margin-right: 15px;
}

.box-radio {
    display: flex;
    margin-bottom: 15px;
}